<template>
  <div class="userDetail">
    <!-- 修改姓名 -->
    <Dialog ref="dialog" :config="config">
      <div>
        <el-input v-model="value"></el-input>
      </div>
    </Dialog>
    <Dialog ref="dialog3" :config="config3">
      <div>
        <el-input v-model="value"></el-input>
      </div>
    </Dialog>
    <!-- 修改唛头-->
    <Dialog ref="dialog2" :config="config2">
      <div>
        <el-input v-model="value"></el-input>
      </div>
    </Dialog>
    <!-- 修改nif -->
    <Dialog ref="dialog5" :config="config4">
      <div>
        <el-input v-model="value"></el-input>
      </div>
    </Dialog>
    <div class="Con">
      <nav>{{ $fanyi("基本信息") }}</nav>
      <div class="ConOpt">
        <label>{{ $fanyi("姓氏") }}：</label>
        <!--        <input-->
        <!--            class="istrueInput"-->
        <!--            autocomplete="new-password"-->
        <!--            v-model="userDatas.user_last_name"-->
        <!--            :placeholder="$fanyi('请输入姓氏')"-->
        <!--        />-->
        <!--      <div class="gou">✔</div>-->
        <div class="userMessage">
          <input
            class="istrueInput"
            disabled
            v-model="userDatas.user_last_name"
          />
          <div class="gou" v-if="userDatas.user_last_name != null">✔</div>
          <button @click="changeUserName('last_name')">
            {{ $fanyi("修改") }}
          </button>
        </div>
      </div>
      <div class="ConOpt">
        <label>{{ $fanyi("名字") }}：</label>
        <div class="userMessage">
          <input v-model="userDatas.user_name" disabled />
          <div class="gou" v-if="userDatas.user_name != null">✔</div>
          <button @click="changeUserName('name')">{{ $fanyi("修改") }}</button>
        </div>
      </div>
      <div class="ConOpt">
        <label>{{ $fanyi("NIF / DNI") }}：</label>
        <div class="userMessage">
          <input
            v-model="userDatas.user_id_card"
            disabled
            :type="showNIF ? 'text' : 'password'"
          />
          <!--        <i class="el-icon-view" @click="showNIF = !showNIF"></i>-->
          <div class="gou" v-if="userDatas.user_id_card != null">✔</div>
          <button @click="changeUserName('NIF')">
            {{ $fanyi("修改") }}
          </button>
        </div>
      </div>
      <div class="ConOpt">
        <label>{{ $fanyi("电子邮件") }}：</label>
        <div class="userMessage">
          <input v-model="userDatas.email" disabled />
          <div class="gou" v-if="userDatas.email != null">✔</div>
          <button
            @click="$fun.routerToPage('/user/changepassworld?type=email')"
          >
            {{ $fanyi("修改") }}
          </button>
        </div>
      </div>
      <div class="ConOpt">
        <label>{{ $fanyi("手机") }}：</label>
        <div class="userMessage">
          <input v-model="userDatas.mobile" disabled />
          <div class="gou" v-if="userDatas.mobile != null">✔</div>
          <button
            @click="$fun.routerToPage('/user/changepassworld?type=mobile')"
          >
            {{ $fanyi("修改") }}
          </button>
        </div>
      </div>
      <!-- <el-button type="primary">保存</el-button> -->
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../components/public/Dialog.vue";
export default {
  data() {
    return {
      userDatas: {},
      value: "",
      showNIF: false, //显示身份证
      showpassword: false, //显示密码
      mark: "",
      markList: [],
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("修改姓氏"),
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
      config2: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("修改唛头"),
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
      config3: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("修改名字"),
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
      config4: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("修改NIF"),
        btnTxt: [this.$fanyi("确认"), this.$fanyi("取消")],
      },
    };
  },
  components: { Dialog },
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api.EuropegetUserInfo().then((res) => {
        if (res.code != 0) return this.message.error(res.msg);
        this.userDatas = res.data;
      });
    },

    // 修改用户名
    changeUserName(type) {
      if (type == "name") {
        this.value = this.userDatas.user_name;
        this.$refs.dialog3.open(
          "",
          () => {
            this.$api
              .userUserNameSave({ user_name: this.value })
              .then((res) => {
                //console.log("userUserNameSave", res);
                if (res.code != 0) return;

                this.getData();
              });
          },
          () => {}
        );
      } else if (type == "last_name") {
        this.value = this.userDatas.user_last_name;
        this.$refs.dialog.open(
          "",
          () => {
            this.$api
              .userUserLastNameSave({ user_last_name: this.value })
              .then((res) => {
                //console.log("userUserNameSave", res);
                if (res.code != 0) return;
                this.getData();
              });
          },
          () => {}
        );
      } else if (type == "NIF") {
        this.value = this.userDatas.user_id_card;
        this.$refs.dialog5.open(
          "",
          () => {
            this.$api
              .userUserIdCardSave({ user_id_card: this.value })
              .then((res) => {
                //console.log("userUserIdCardSave", res);
                if (res.code != 0) return;
                this.getData();
              });
          },
          () => {}
        );
      }
    },
  },
};
</script>
<style lang='scss' scoped='scoped'>
@import "../../../../css/mixin.scss";
.userDetail {
  // height: 392px;
  padding-bottom: 50px;
  background-color: #fff;
  nav {
    padding-bottom: 30px;
    font-size: 18px;

    color: #000000;
    line-height: 24px;
  }
  .Con {
    padding: 50px 0 0 60px;

    .ConOpt {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      .userMessage {
        position: relative;
        display: flex;
        align-items: center;
      }
      .gou {
        position: absolute;
        font-weight: bold;
        left: 350px;
        top: 13px;
        color: #417db4;
        &.regCodegou {
          right: 222px;
        }
      }
      label {
        display: inline-block;
        text-align: left;
        font-size: 14px;
        color: #000000;
        line-height: 19px;
        margin-bottom: 10px;
      }
      input {
        width: 379px;
        height: 48px;
        background: #fff;
        border: 1px solid #dedede;
        border-radius: 5px;
        padding: 0 10px;
        transition: 0.4s;
      }
      button {
        width: 30px;
        height: 19px;
        font-size: 14px;
        margin-left: 20px;
        color: $homePageSubjectColor;
        line-height: 19px;
        background-color: transparent;
        user-select: none;
      }
    }
    .el-icon-view {
      position: absolute;
      left: 380px;
      top: 5px;
    }
    .el-button {
      width: 96px;
      height: 40px;
      background: #1e2997;
      border-radius: 4px;
      margin-top: 10px;
      margin-left: 135px;
      font-size: 16px;
      color: #ffffff;
      line-height: 18px;
    }
  }
}
</style>